<script>
import { loadPointRecordList } from '@/api'
import STable from '@/components/Table'
import moment from 'moment'
import { mapGetters } from 'vuex'
import BuyPoints from '@/views/cert/modal/BuyPoints.vue'
import { getI18nLanguage } from '@/locales'

export default {
  name: 'Score',
  components: {
    STable
  },
  data () {
    return {
      columns: [{
        title: this.$t('user.point.variable'),
        dataIndex: 'absolute_value',
        scopedSlots: { customRender: 'absolute_value' },
        key: 'absolute_value',
        align: 'center'
      }, {
        title: this.$t('user.point.left'),
        dataIndex: 'left_value',
        key: 'left_value',
        align: 'center'
      }, {
        title: this.$t('user.point.change.desc'),
        dataIndex: 'remark',
        key: 'desc',
        align: 'center'
      }, {
        title: this.$t('user.point.change.time'),
        dataIndex: 'create_time',
        scopedSlots: { customRender: 'create_time' },
        key: 'time',
        align: 'center'
      }],
      columns2: [{
        title: this.$t('user.point.task.item'),
        dataIndex: 'name',
        key: 'name',
        width: 280
      }, {
        title: this.$t('user.point.task.client'),
        dataIndex: 'client',
        key: 'client',
        align: 'center',
        width: 280
      }, {
        title: this.$t('user.point.task.point'),
        dataIndex: 'score',
        key: 'score',
        align: 'center',
        width: 100
      }, {
        title: this.$t('user.point.task.remark'),
        dataIndex: 'remark',
        key: 'remark'
      }],
      columns3: [{
        title: this.$t('user.point.item'),
        dataIndex: 'name',
        key: 'name',
        width: 380
      }, {
        title: this.$t('user.point.task.point'),
        dataIndex: 'score',
        key: 'score',
        align: 'center',
        width: 100
      }, {
        title: this.$t('user.point.task.remark'),
        dataIndex: 'remark',
        key: 'desc'
      }],
      pointQueryParams: {},
      loadPointList: (paramter) => {
        return loadPointRecordList(Object.assign(paramter, this.pointQueryParams)).then(res => {
           return res.result
        })
      },
      dataSource: [{
        name: this.$t('user.point.task.sign-in'),
        client: this.$t('client.type.app'),
        score: '+1/+2',
        remark: this.$t('user.point.task.sign-in.remark')
      }, {
        name: this.$t('user.point.task.ad'),
        score: '+2',
        client: this.$t('client.type.app'),
        remark: this.$t('user.point.task.ad.tip')
      }, {
        name: this.$t('user.point.task.reg'),
        score: '+100',
        client: this.$t('client.type.web'),
        remark: this.$t('user.point.task.reg.tip')
      }, {
        name: this.$t('user.point.task.feedback'),
        score: '+20',
        client: this.$t('client.type.web'),
        remark: this.$t('user.point.task.feedback.tip')
      }, {
        name: this.$t('user.point.task.docking'),
        score: '+80',
        client: this.$t('client.type.web'),
        remark: this.$t('user.point.task.docking.tip')
      }, {
        name: this.$t('user.point.task.visit'),
        score: '+30',
        client: this.$t('client.type.web'),
        remark: this.$t('user.point.task.visit.tip')
      }],
      dataSource2: [{
        name: this.$t('user.point.function.renew.auto'),
        score: -20,
        remark: this.$t('user.point.function.renew.auto.tip')
      }, {
        name: this.$t('user.point.function.renew.manual'),
        score: -5,
        remark: this.$t('user.point.function.renew.manual.tip')
      }]
    }
  },
  methods: {
    ...mapGetters(['userInfo']),
    moment,
    buyPoints () {
      this.showModal(BuyPoints, this.$t('user.point.buy'), 600, getI18nLanguage() === 'zh-CN' ? 500 : 240)
    }
  }
}
</script>

<template>
  <div>
    <div class="ant-card">
      <div style="padding: 8px;overflow:hidden;display: flex;line-height: 18px;">
        <div style="flex:1;">
          <div>
            <span style="line-height: 36px;">{{ $t('user.point.current') }}</span><span style="color: orange;margin: 0 8px;">{{ userInfo().point }}</span>
            <a-icon type="redo" style="cursor: pointer;" title=""></a-icon>
          </div>

          <div>{{ $t('user.point.tip') }}</div>
        </div>
        <div style="display: table;margin-left: 16px;">
          <div style="display: table-cell;vertical-align: middle;height: 32px;">
            <a-button type="primary" @click="buyPoints">{{ $t('user.point.buy') }}</a-button>
          </div>
        </div>

      </div>
    </div>
    <div style="margin-top: 8px;" class="ant-card">
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" :tab="$t('user.point.record')">
          <div></div>
          <div>
            <s-table :columns="columns" :data="loadPointList" bordered size="small">
              <span slot="absolute_value" slot-scope="text,record">{{ record.absolute_value>0 ? ('+'+record.absolute_value) : record.absolute_value }}</span>
              <span slot="create_time" slot-scope="text,record">{{ moment(record.create_time).format("YYYY-MM-DD HH:mm:ss") }}</span>
            </s-table>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('user.point.rule')" force-render>
          <div style="padding: 8px;">
            <div style="font-weight: bold;line-height: 32px;">
              {{ $t('user.point.reward.rule') }}
            </div>
            <a-table :columns="columns2" bordered :dataSource="dataSource" :pagination="false" size="small"></a-table>
            <div style="margin-top: 32px;font-weight: bold;line-height: 32px;">  {{ $t('user.point.deduction.rule') }}</div>
            <a-table :columns="columns3" bordered :dataSource="dataSource2" :pagination="false" size="small"></a-table>
          </div>

        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<style scoped>

</style>
