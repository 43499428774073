<script>
export default {
  name: 'BuyPoints',
  data () {
    return {
      checkedPoints: 50,
      plainOptions: [
        { label: '10' + this.$t('user.point.charge.point'), value: 10 },
        { label: '20' + this.$t('user.point.charge.point'), value: 20 },
        { label: '50' + this.$t('user.point.charge.point'), value: 50 },
        { label: '100' + this.$t('user.point.charge.point'), value: 100 },
        { label: '200' + this.$t('user.point.charge.point'), value: 200 },
        { label: this.$t('user.point.charge.custom'), value: 0 }
      ],
      customPoints: 40
    }
  }
}
</script>

<template>
  <div style="width: 100%;padding: 8px 16px;">
    <div>
      <div>{{ $t('user.point.buy.num.tip') }}</div>
      <div style="margin-top: 12px;">
        <a-radio-group button-style="solid" v-model="checkedPoints">
          <a-radio-button :value="item.value" v-for="item in plainOptions">
            {{ item.label }}
          </a-radio-button>
        </a-radio-group>
      </div>
      <div style="margin-top: 28px;" v-if="checkedPoints === 0">Custom points:<a-input-number v-model="customPoints" :step="10" :min="10" style="width: 120px;margin-left: 8px;" /></div>

      <div style="position: absolute;width: 95%;bottom:8px;overflow: hidden;">
        <div class="float-left" style="line-height:32px;color:#0099FF">{{ $t('user.point.buy.pay') }}{{ (checkedPoints||customPoints)/10 }} USD</div>
        <div class="float-right"><a-button>{{ $t('user.action.cancel') }}</a-button><a-button type="primary" class="margin-left-16">{{ $t('user.point.buy.submit') }}</a-button></div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
